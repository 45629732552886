@import "../../../assets/scss/personalizado/mc-soft-variables.scss";

.notification-tarjeta__elemento {
	cursor: pointer;
	.d-flex {
		padding: 0.75rem 1rem;
		display: flex;
		&:hover {
			background-color: $colorFondoNotificacionLeidaHover;
		}
	}
}

.notification-tarjeta__elemento-nuevo {
	cursor: pointer;
	.d-flex {
		background-color: $colorFondoNotificacionNueva;
		padding: 0.75rem 1rem;
		display: flex;
		&:hover {
			background-color: $colorFondoNotificacionNuevaHover;
		}
	}
}
