.mc-pantalla-carga__fondo{
    background-color: #000000;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 10000;
    &.oculto{
        display: none;
    }
}

.mc-pantalla-carga__fondo--oculto{
	@extend .mc-pantalla-carga__fondo;
	display: none;
}

.mc-pantalla-carga__icono{
	font-size: 50px;
}

.mc-pantalla-carga__icono--girando{
	@extend .mc-pantalla-carga__icono;
	animation: girar 1s ease alternate infinite;
}

.mc-pantalla-carga__mensaje{
	color: #ffffff;
	display: block;
	left: 0px;
	position: fixed;
	right: 0px;
	text-align: center;
	top: 40%;
	z-index: 1001;
}

@keyframes girar {
	0% {
		transform: rotatey(0deg);
	}
	50% {
		transform: rotatey(180deg);
	}
	100% {
		transform: rotatey(360deg);
	}
}