.mc-lista-ordenable__elemento-agarradera {
	background-color: #dadada;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	cursor: grab !important;
	display: block;
	height: 25px;
	text-align: center;
	&:hover {
		background-color: #a1a1a1;
	}
}

.mc-lista-ordenable__elemento-boton--izquierdo {
	border-top-left-radius: 0px;
}

.mc-lista-ordenable__elemento-boton--derecho {
	border-top-right-radius: 0px;
}

.mc-lista-ordenable__elemento-contenedor {
	background-color: #ebe7e7;
	padding: 0;
	margin-right: 10px;
}

.mc-lista-ordenable__elemento-marco {
    display:block;
    margin:auto;
}