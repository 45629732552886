.usuario-tarjeta {
	color: #74788d;
	h3 {
		margin-bottom: 20px;
	}
	p {
		font-size: 17px;
		margin-bottom: 10px;
	}
}

.usuario-tarjeta__foto-perfil-contenedor {
	@extend .usuario-tarjeta;
	margin-bottom: 10px;
	:hover {
		cursor: pointer;
	}
}

.usuario-tarjeta__foto-perfil {
	width: 100%;
}

.usuario-tarjeta__nombre {
	@extend .usuario-tarjeta;
	text-align: center;
}

.usuario-tarjeta__informacion {
	@extend .usuario-tarjeta;
	.bg-danger {
		cursor: default;
	}
}

.usuario-tarjeta__red-social {
	text-align: center;
}

.usuario-tarjeta__red-social-boton {
	margin-right: 10px;
	width: 40px;
}
