.aplicacion-formulario {
	h5 {
		margin-top: 20px;
	}
}

.aplicacion-formulario__vista-previa {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
	text-align: center;
}

.aplicacion-formulario__vista-previa-logo {
	@extend .aplicacion-formulario__vista-previa;
	height: 100px;
}

.aplicacion-formulario__vista-previa-logo-cuadrado {
	@extend .aplicacion-formulario__vista-previa;
	height: 150px;
}

.aplicacion-formulario__vista-previa--claro {
	@extend .aplicacion-formulario__vista-previa;
	align-items: center;
	background-color: #cccccc;
	display: flex;
	justify-content: center;
}

.aplicacion-formulario__vista-previa-logo--claro {
	@extend .aplicacion-formulario__vista-previa--claro;
	height: 100px;
}

.aplicacion-formulario__vista-previa-logo-cuadrado--claro {
	@extend .aplicacion-formulario__vista-previa--claro;
	height: 150px;
}
