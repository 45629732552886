.mc-switch-redondo {
	margin-bottom: 20px;
}
.mc-switch-redondo__etiqueta--lineal {
	margin-left: 10px;
	position: relative;
	top: 5px;
}
.mc-switch-redondo__informacion--lineal {
	position: relative;
	top: 5px;
}
