.mc-selector-imagen__barra-herramientas {
	align-items: center;
	bottom: 0;
	display: block;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	width: 50%;
	text-align: center;
}

.mc-selector-imagen__barra-herramientas-botones {
	text-align: right;
	.btn{
		margin-left: 5px;
	}
}

.mc-selector-imagen__barra-herramientas-etiqueta {
	font-weight: bold;
	font-size: 15pt;
}

.mc-selector-imagen__imagen-predeterminada {
	align-items: center;
	bottom: 0;
	display: block;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	width: 50%;
}

.mc-selector-imagen__imagen-predeterminada-icono {
	color: rgb(211, 210, 210);
	font-size: 150px;
	text-align: center;
}

.mc-selector-imagen__imagen-predeterminada-mensaje {
	color: rgb(211, 210, 210);
	font-size: 20px;
	text-align: center;
}

.mc-selector-imagen__imagen-seleccionada {
	position: absolute;
	left: 0;
	right: 0;
}

@media (min-width: 1231px) {
	
	.mc-selector-imagen__barra-herramientas {
		height: 200px;
	}

	.mc-selector-imagen__barra-herramientas-etiqueta {
		margin-bottom: -15px;
		margin-top: 10px;
	}
	
	.mc-selector-imagen__imagen-predeterminada {
		height: 500px;
	}

	.mc-selector-imagen__imagen-seleccionada {
		top: 130px;
		bottom: 200px;
	}
}

@media (min-width: 1101px) and (max-width: 1230px) {
	
	.mc-selector-imagen__barra-herramientas {
		height: 200px;
	}
	
	.mc-selector-imagen__barra-herramientas-botones-texto {
		display: none;
	}

	.mc-selector-imagen__barra-herramientas-etiqueta {
		margin-bottom: -15px;
		margin-top: 10px;
	}
	
	.mc-selector-imagen__imagen-predeterminada {
		height: 500px;
	}

	.mc-selector-imagen__imagen-seleccionada {
		top: 130px;
		bottom: 200px;
	}
}

@media (min-width: 993px) and (max-width: 1100px) {
	
	.mc-selector-imagen__barra-herramientas {
		height: 210px;
	}
	
	.mc-selector-imagen__barra-herramientas-botones {
		margin-top: 10px;
	}
	
	.mc-selector-imagen__barra-herramientas-botones-texto {
		display: none;
	}

	.mc-selector-imagen__barra-herramientas-etiqueta {
		margin-bottom: -15px;
		margin-top: 10px;
	}
	
	.mc-selector-imagen__imagen-predeterminada {
		height: 560px;
	}
	
	.mc-selector-imagen__imagen-predeterminada-icono {
		font-size: 130px;
	}
	
	.mc-selector-imagen__imagen-predeterminada-mensaje {
		font-size: 14px;
	}

	.mc-selector-imagen__imagen-seleccionada {
		top: 130px;
		bottom: 300px;
	}
}

@media (min-width: 601px) and (max-width: 992px) {
	
	.mc-selector-imagen__barra-herramientas {
		height: 320px;
	}
	
	.mc-selector-imagen__barra-herramientas-botones {
		margin-top: 10px;
		text-align: center;
	}

	.mc-selector-imagen__barra-herramientas-etiqueta {
		margin-bottom: -15px;
		margin-top: 10px;
	}
	
	.mc-selector-imagen__imagen-predeterminada {
		height: 520px;
	}
	
	.mc-selector-imagen__imagen-predeterminada-icono {
		font-size: 100px;
	}
	
	.mc-selector-imagen__imagen-predeterminada-mensaje {
		font-size: 12px;
	}

	.mc-selector-imagen__imagen-seleccionada {
		top: 130px;
		bottom: 340px;
	}
}

@media (max-width: 600px) {
	
	.mc-selector-imagen__barra-herramientas {
		height: 320px;
	}
	
	.mc-selector-imagen__barra-herramientas-botones {
		margin-top: 10px;
		text-align: center;
	}
	
	.mc-selector-imagen__barra-herramientas-botones-texto {
		display: none;
	}

	.mc-selector-imagen__barra-herramientas-etiqueta {
		margin-bottom: -15px;
		margin-top: 10px;
	}
	
	.mc-selector-imagen__imagen-predeterminada {
		height: 520px;
	}
	
	.mc-selector-imagen__imagen-predeterminada-icono {
		font-size: 100px;
	}
	
	.mc-selector-imagen__imagen-predeterminada-mensaje {
		font-size: 12px;
	}

	.mc-selector-imagen__imagen-seleccionada {
		top: 130px;
		bottom: 340px;
	}
}