//****************************************************************************************************
// Botones
//****************************************************************************************************
//----------------------------------------------------------------------------------------------------
// Muestra
//----------------------------------------------------------------------------------------------------
.btn-xxx {
	color: #fff;
	background-color: #556ee6; // Color Original
	border-color: #556ee6; // Color Original
}

.btn-xxx:hover {
	color: #fff;
	background-color: #485ec4; // Color Original -d -10 -22
	border-color: #4458b8; // Color Original -11 -16 -2e
}

.btn-check:focus + .btn-xxx,
.btn-xxx:focus {
	color: #fff;
	background-color: #485ec4; // Color Original -d -10 -22
	border-color: #4458b8; // Color Original -11 -16 -2e
	-webkit-box-shadow: 0 0 0 0.15rem rgba(111, 132, 234, 0.5); // Color Original +1a +16 +4 RGB Alpha 0.5
	box-shadow: 0 0 0 0.15rem rgba(111, 132, 234, 0.5); // Color Original +1a +16 +4 RGB Alpha 0.5
}

.btn-check:checked + .btn-xxx,
.btn-check:active + .btn-xxx,
.btn-xxx:active,
.btn-xxx.active,
.show > .btn-xxx.dropdown-toggle {
	color: #fff;
	background-color: #4458b8; // Color Original -11 -16 -2e
	border-color: #4053ad; // Color Original -15 -1b -39
}

.btn-check:checked + .btn-xxx:focus,
.btn-check:active + .btn-xxx:focus,
.btn-xxx:active:focus,
.btn-xxx.active:focus,
.show > .btn-xxx.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(111, 132, 234, 0.5); // // Color Original +1a +16 +4 RGB Alpha 0.5
	box-shadow: 0 0 0 0.15rem rgba(111, 132, 234, 0.5); // // Color Original +1a +16 +4 RGB Alpha 0.5
}

.btn-xxx:disabled,
.btn-xxx.disabled {
	color: #fff;
	background-color: #556ee6; // Color Original
	border-color: #556ee6; // Color Original
}
//----------------------------------------------------------------------------------------------------
// Muestra Outline
//----------------------------------------------------------------------------------------------------
.btn-outline-xxx {
	color: #556ee6; // Color Original
	border-color: #556ee6; // Color Original
}

.btn-outline-xxx:hover {
	color: #fff;
	background-color: #556ee6; // Color Original
	border-color: #556ee6; // Color Original
}

.btn-check:focus + .btn-outline-xxx,
.btn-outline-xxx:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(85, 110, 230, 0.5); // Color Original RGB Alpha 0.5
	box-shadow: 0 0 0 0.15rem rgba(85, 110, 230, 0.5); // Color Original RGB Alpha 0.5
}

.btn-check:checked + .btn-outline-xxx,
.btn-check:active + .btn-outline-xxx,
.btn-outline-xxx:active,
.btn-outline-xxx.active,
.btn-outline-xxx.dropdown-toggle.show {
	color: #fff;
	background-color: #556ee6; // Color Original
	border-color: #556ee6; // Color Original
}

.btn-check:checked + .btn-outline-xxx:focus,
.btn-check:active + .btn-outline-xxx:focus,
.btn-outline-xxx:active:focus,
.btn-outline-xxx.active:focus,
.btn-outline-xxx.dropdown-toggle.show:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(85, 110, 230, 0.5); // Color Original RGB Alpha 0.5
	box-shadow: 0 0 0 0.15rem rgba(85, 110, 230, 0.5); // Color Original RGB Alpha 0.5
}

.btn-outline-xxx:disabled,
.btn-outline-xxx.disabled {
	color: #556ee6; // Color Original
	background-color: transparent;
}
//----------------------------------------------------------------------------------------------------
// Facebook
//----------------------------------------------------------------------------------------------------
.btn-facebook {
	color: #fff;
	background-color: #3c5a99;
	border-color: #3c5a99;
}

.btn-facebook:hover {
	color: #fff;
	background-color: #2f4a77;
	border-color: #2b446b;
}

.btn-check:focus + .btn-facebook,
.btn-facebook:focus {
	color: #fff;
	background-color: #2f4a77;
	border-color: #2b446b;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(86, 112, 157, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(86, 112, 157, 0.5);
}

.btn-check:checked + .btn-facebook,
.btn-check:active + .btn-facebook,
.btn-facebook:active,
.btn-facebook.active,
.show > .btn-facebook.dropdown-toggle {
	color: #fff;
	background-color: #2b446b;
	border-color: #273f60;
}

.btn-check:checked + .btn-facebook:focus,
.btn-check:active + .btn-facebook:focus,
.btn-facebook:active:focus,
.btn-facebook.active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(86, 112, 157, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(86, 112, 157, 0.5);
}

.btn-facebook:disabled,
.btn-facebook.disabled {
	color: #fff;
	background-color: #3c5a99;
	border-color: #3c5a99;
}

//----------------------------------------------------------------------------------------------------
// Facebook Outline
//----------------------------------------------------------------------------------------------------
.btn-outline-facebook {
	color: #3c5a99;
	border-color: #3c5a99;
}

.btn-outline-facebook:hover {
	color: #fff;
	background-color: #3c5a99;
	border-color: #3c5a99;
}

.btn-check:focus + .btn-outline-facebook,
.btn-outline-facebook:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(60, 90, 153, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(60, 90, 153, 0.5);
}

.btn-check:checked + .btn-outline-facebook,
.btn-check:active + .btn-outline-facebook,
.btn-outline-facebook:active,
.btn-outline-facebook.active,
.btn-outline-facebook.dropdown-toggle.show {
	color: #fff;
	background-color: #3c5a99;
	border-color: #3c5a99;
}

.btn-check:checked + .btn-outline-facebook:focus,
.btn-check:active + .btn-outline-facebook:focus,
.btn-outline-facebook:active:focus,
.btn-outline-facebook.active:focus,
.btn-outline-facebook.dropdown-toggle.show:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(60, 90, 153, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(60, 90, 153, 0.5);
}

.btn-outline-facebook:disabled,
.btn-outline-facebook.disabled {
	color: #3c5a99;
	background-color: transparent;
}
//----------------------------------------------------------------------------------------------------
// Gris
//----------------------------------------------------------------------------------------------------
.btn-gris {
	color: #fff;
	background-color: #ced4da;
	border-color: #ced4da;
}

.btn-gris:hover {
	color: #fff;
	background-color: #c1c4b8;
	border-color: #bdbeac;
}

.btn-check:focus + .btn-gris,
.btn-gris:focus {
	color: #fff;
	background-color: #c1c4b8;
	border-color: #bdbeac;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(232, 234, 222, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(232, 234, 222, 0.5);
}

.btn-check:checked + .btn-gris,
.btn-check:active + .btn-gris,
.btn-gris:active,
.btn-gris.active,
.show > .btn-gris.dropdown-toggle {
	color: #fff;
	background-color: #bdbeac;
	border-color: #b9b9a1;
}

.btn-check:checked + .btn-gris:focus,
.btn-check:active + .btn-gris:focus,
.btn-gris:active:focus,
.btn-gris.active:focus,
.show > .btn-gris.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(232, 234, 222, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(232, 234, 222, 0.5);
}

.btn-gris:disabled,
.btn-gris.disabled {
	color: #fff;
	background-color: #ced4da;
	border-color: #ced4da;
}
//----------------------------------------------------------------------------------------------------
// Gris Outline
//----------------------------------------------------------------------------------------------------
.btn-outline-gris {
	color: #ced4da;
	border-color: #ced4da;
}

.btn-outline-gris:hover {
	color: #fff;
	background-color: #ced4da;
	border-color: #ced4da;
}

.btn-check:focus + .btn-outline-gris,
.btn-outline-gris:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(206, 212, 218, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(206, 212, 218, 0.5);
}

.btn-check:checked + .btn-outline-gris,
.btn-check:active + .btn-outline-gris,
.btn-outline-gris:active,
.btn-outline-gris.active,
.btn-outline-gris.dropdown-toggle.show {
	color: #fff;
	background-color: #ced4da;
	border-color: #ced4da;
}

.btn-check:checked + .btn-outline-gris:focus,
.btn-check:active + .btn-outline-gris:focus,
.btn-outline-gris:active:focus,
.btn-outline-gris.active:focus,
.btn-outline-gris.dropdown-toggle.show:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(206, 212, 218, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(206, 212, 218, 0.5);
}

.btn-outline-gris:disabled,
.btn-outline-gris.disabled {
	color: #ced4da;
	background-color: transparent;
}

//----------------------------------------------------------------------------------------------------
// Instagram
//----------------------------------------------------------------------------------------------------
.btn-instagram {
	color: #fff;
	background-color: #f82a7c;
	border-color: #f82a7c;
}

.btn-instagram:hover {
	color: #fff;
	background-color: #eb1a5a;
	border-color: #e7144e;
}

.btn-check:focus + .btn-instagram,
.btn-instagram:focus {
	color: #fff;
	background-color: #eb1a5a;
	border-color: #e7144e;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(255, 64, 128, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(255, 64, 128, 0.5);
}

.btn-check:checked + .btn-instagram,
.btn-check:active + .btn-instagram,
.btn-instagram:active,
.btn-instagram.active,
.show > .btn-instagram.dropdown-toggle {
	color: #fff;
	background-color: #e7144e;
	border-color: #e30f43;
}

.btn-check:checked + .btn-instagram:focus,
.btn-check:active + .btn-instagram:focus,
.btn-instagram:active:focus,
.btn-instagram.active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(255, 64, 128, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(255, 64, 128, 0.5);
}

.btn-instagram:disabled,
.btn-instagram.disabled {
	color: #fff;
	background-color: #f82a7c;
	border-color: #f82a7c;
}
//----------------------------------------------------------------------------------------------------
// Instagram Outline
//----------------------------------------------------------------------------------------------------
.btn-outline-instagram {
	color: #f82a7c;
	border-color: #f82a7c;
}

.btn-outline-instagram:hover {
	color: #fff;
	background-color: #f82a7c;
	border-color: #f82a7c;
}

.btn-check:focus + .btn-outline-instagram,
.btn-outline-instagram:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(248, 42, 124, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(248, 42, 124, 0.5);
}

.btn-check:checked + .btn-outline-instagram,
.btn-check:active + .btn-outline-instagram,
.btn-outline-instagram:active,
.btn-outline-instagram.active,
.btn-outline-instagram.dropdown-toggle.show {
	color: #fff;
	background-color: #f82a7c;
	border-color: #f82a7c;
}

.btn-check:checked + .btn-outline-instagram:focus,
.btn-check:active + .btn-outline-instagram:focus,
.btn-outline-instagram:active:focus,
.btn-outline-instagram.active:focus,
.btn-outline-instagram.dropdown-toggle.show:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(248, 42, 124, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(248, 42, 124, 0.5);
}

.btn-outline-instagram:disabled,
.btn-outline-instagram.disabled {
	color: #f82a7c;
	background-color: transparent;
}

//----------------------------------------------------------------------------------------------------
// LinkedIn
//----------------------------------------------------------------------------------------------------
.btn-linkedin {
	color: #fff;
	background-color: #0078ba;
	border-color: #0078ba;
}

.btn-linkedin:hover {
	color: #fff;
	background-color: #006898;
	border-color: #00628c;
}

.btn-check:focus + .btn-linkedin,
.btn-linkedin:focus {
	color: #fff;
	background-color: #006898;
	border-color: #00628c;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(26, 142, 190, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(26, 142, 190, 0.5);
}

.btn-check:checked + .btn-linkedin,
.btn-check:active + .btn-linkedin,
.btn-linkedin:active,
.btn-linkedin.active,
.show > .btn-linkedin.dropdown-toggle {
	color: #fff;
	background-color: #00628c;
	border-color: #005d81;
}

.btn-check:checked + .btn-linkedin:focus,
.btn-check:active + .btn-linkedin:focus,
.btn-linkedin:active:focus,
.btn-linkedin.active:focus,
.show > .btn-linkedin.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(26, 142, 190, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(26, 142, 190, 0.5);
}

.btn-linkedin:disabled,
.btn-linkedin.disabled {
	color: #fff;
	background-color: #0078ba;
	border-color: #0078ba;
}
//----------------------------------------------------------------------------------------------------
// LinkedIn Outline
//----------------------------------------------------------------------------------------------------
.btn-outline-linkedin {
	color: #0078ba;
	border-color: #0078ba;
}

.btn-outline-linkedin:hover {
	color: #fff;
	background-color: #0078ba;
	border-color: #0078ba;
}

.btn-check:focus + .btn-outline-linkedin,
.btn-outline-linkedin:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(0, 120, 186, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(0, 120, 186, 0.5);
}

.btn-check:checked + .btn-outline-linkedin,
.btn-check:active + .btn-outline-linkedin,
.btn-outline-linkedin:active,
.btn-outline-linkedin.active,
.btn-outline-linkedin.dropdown-toggle.show {
	color: #fff;
	background-color: #0078ba;
	border-color: #0078ba;
}

.btn-check:checked + .btn-outline-linkedin:focus,
.btn-check:active + .btn-outline-linkedin:focus,
.btn-outline-linkedin:active:focus,
.btn-outline-linkedin.active:focus,
.btn-outline-linkedin.dropdown-toggle.show:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(0, 120, 186, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(0, 120, 186, 0.5);
}

.btn-outline-linkedin:disabled,
.btn-outline-linkedin.disabled {
	color: #0078ba;
	background-color: transparent;
}

//----------------------------------------------------------------------------------------------------
// Twitter
//----------------------------------------------------------------------------------------------------
.btn-twitter {
	color: #fff;
	background-color: #00abe3;
	border-color: #00abe3;
}

.btn-twitter:hover {
	color: #fff;
	background-color: #009bc1;
	border-color: #0095b5;
}

.btn-check:focus + .btn-twitter,
.btn-twitter:focus {
	color: #fff;
	background-color: #009bc1;
	border-color: #0095b5;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(26, 193, 231, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(26, 193, 231, 0.5);
}

.btn-check:checked + .btn-twitter,
.btn-check:active + .btn-twitter,
.btn-twitter:active,
.btn-twitter.active,
.show > .btn-twitter.dropdown-toggle {
	color: #fff;
	background-color: #0095b5;
	border-color: #0090aa;
}

.btn-check:checked + .btn-twitter:focus,
.btn-check:active + .btn-twitter:focus,
.btn-twitter:active:focus,
.btn-twitter.active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(26, 193, 231, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(26, 193, 231, 0.5);
}

.btn-twitter:disabled,
.btn-twitter.disabled {
	color: #fff;
	background-color: #00abe3;
	border-color: #00abe3;
}
//----------------------------------------------------------------------------------------------------
// Twitter Outline
//----------------------------------------------------------------------------------------------------
.btn-outline-twitter {
	color: #00abe3;
	border-color: #00abe3;
}

.btn-outline-twitter:hover {
	color: #fff;
	background-color: #00abe3;
	border-color: #00abe3;
}

.btn-check:focus + .btn-outline-twitter,
.btn-outline-twitter:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(0, 171, 227, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(0, 171, 227, 0.5);
}

.btn-check:checked + .btn-outline-twitter,
.btn-check:active + .btn-outline-twitter,
.btn-outline-twitter:active,
.btn-outline-twitter.active,
.btn-outline-twitter.dropdown-toggle.show {
	color: #fff;
	background-color: #00abe3;
	border-color: #00abe3;
}

.btn-check:checked + .btn-outline-twitter:focus,
.btn-check:active + .btn-outline-twitter:focus,
.btn-outline-twitter:active:focus,
.btn-outline-twitter.active:focus,
.btn-outline-twitter.dropdown-toggle.show:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(0, 171, 227, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(0, 171, 227, 0.5);
}

.btn-outline-twitter:disabled,
.btn-outline-twitter.disabled {
	color: #00abe3;
	background-color: transparent;
}

//----------------------------------------------------------------------------------------------------
// YouTube
//----------------------------------------------------------------------------------------------------
.btn-youtube {
	color: #fff;
	background-color: #ef1a19;
	border-color: #ef1a19;
}

.btn-youtube:hover {
	color: #fff;
	background-color: #e20a00;
	border-color: #de0400;
}

.btn-check:focus + .btn-youtube,
.btn-youtube:focus {
	color: #fff;
	background-color: #e20a00;
	border-color: #de0400;
	-webkit-box-shadow: 0 0 0 0.15rem rgba(255, 48, 29, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(255, 48, 29, 0.5);
}

.btn-check:checked + .btn-youtube,
.btn-check:active + .btn-youtube,
.btn-youtube:active,
.btn-youtube.active,
.show > .btn-youtube.dropdown-toggle {
	color: #fff;
	background-color: #de0400;
	border-color: #da0000;
}

.btn-check:checked + .btn-youtube:focus,
.btn-check:active + .btn-youtube:focus,
.btn-youtube:active:focus,
.btn-youtube.active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(255, 48, 29, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(255, 48, 29, 0.5);
}

.btn-youtube:disabled,
.btn-youtube.disabled {
	color: #fff;
	background-color: #ef1a19;
	border-color: #ef1a19;
}
//----------------------------------------------------------------------------------------------------
// YouTube Outline
//----------------------------------------------------------------------------------------------------
.btn-outline-youtube {
	color: #ef1a19;
	border-color: #ef1a19;
}

.btn-outline-youtube:hover {
	color: #fff;
	background-color: #ef1a19;
	border-color: #ef1a19;
}

.btn-check:focus + .btn-outline-youtube,
.btn-outline-youtube:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(239, 26, 25, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(239, 26, 25, 0.5);
}

.btn-check:checked + .btn-outline-youtube,
.btn-check:active + .btn-outline-youtube,
.btn-outline-youtube:active,
.btn-outline-youtube.active,
.btn-outline-youtube.dropdown-toggle.show {
	color: #fff;
	background-color: #ef1a19;
	border-color: #ef1a19;
}

.btn-check:checked + .btn-outline-youtube:focus,
.btn-check:active + .btn-outline-youtube:focus,
.btn-outline-youtube:active:focus,
.btn-outline-youtube.active:focus,
.btn-outline-youtube.dropdown-toggle.show:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgba(239, 26, 25, 0.5);
	box-shadow: 0 0 0 0.15rem rgba(239, 26, 25, 0.5);
}

.btn-outline-youtube:disabled,
.btn-outline-youtube.disabled {
	color: #ef1a19;
	background-color: transparent;
}
//****************************************************************************************************
// Tabs
//****************************************************************************************************
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	&.link-danger {
		color: #fff !important;
		background-color: #f46a6a;
	}
}

.nav-pills .nav-link.link-danger,
.nav-pills .show > .nav-link {
	&.link-danger {
		border: 1px solid #f46a6a;
	}
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	&.link-warning {
		color: #fff !important;
		background-color: #b1850c;
	}
}

.nav-pills .nav-link.link-warning,
.nav-pills .show > .nav-link {
	&.link-warning {
		border: 1px solid #b1850c;
	}
}

.tab-content {
	width: 100%;
}
