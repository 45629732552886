//
// avatar.scss
//

.avatar-xs {
  height: 32px;
  width: 32px;
}

.avatar-sm {
  height: 50px;
  width: 50px;
}

.avatar-md {
  height: 75px;
  width: 75px;
}

.avatar-lg {
  height: 100px;
  width: 100px;
}

.avatar-xl {
  height: 125px;
  width: 125px;
}

.avatar-2xl {
  height: 150px;
  width: 150px;
}

.avatar-3xl {
  height: 175px;
  width: 175px;
}

.avatar-4xl {
  height: 200px;
  width: 200px;
}

.avatar-5xl {
  height: 225px;
  width: 225px;
}

.avatar-6xl {
  height: 250px;
  width: 250px;
}

.avatar-7xl {
  height: 275px;
  width: 275px;
}

.avatar-8xl {
  height: 300px;
  width: 300px;
}

.avatar-9xl {
  height: 325px;
  width: 325px;
}

.avatar-10xl {
  height: 350px;
  width: 350px;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}