.radio-toolbar input[type="radio"] {
	opacity: 0;
	position: fixed;
	width: 0;
}

.radio-toolbar label {
	background-color: #f6f6f6;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	padding: 5px 10px;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #5b73e8;
  border: none;
  color: #ffffff;
}