.mc-selector-archivos__archivos-contenedor {
	padding: 10px;
}

.mc-selector-archivos__archivos-titulo {
	font-weight: bold;
}

.mc-selector-archivos__archivos-lista {
	border: solid 2px #99caff;
}

.mc-selector-archivos__zona-soltar-archivos-contenedor {
	background-color: #e6f2ff;
	padding: 20px;
}

.mc-selector-archivos__zona-soltar-archivos-contenido {
	background-color: #e6f2ff;
	border: dashed 2px #99caff;
	padding: 20px;
	text-align: center;
}

.mc-selector-archivos__miniatura-contenedor {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 16px;
}

.mc-selector-archivos__miniatura-contenido {
	overflow: hidden;
}

.mc-selector-archivos__miniatura-imagen {
	max-height: 98px;
	max-width: 98px;
}

.mc-selector-archivos__miniatura-marco {
	border: 1px solid #eaeaea;
	border-radius: 2px;
	box-sizing: border-box;
	height: 100px;
	margin-bottom: 8px;
	margin-right: 8px;
	text-align: center;
	width: 100px;
}