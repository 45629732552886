.avaluos-formulario__contenedor {
	margin: 0px 0px 0px 20px;
}

.avaluos-formulario__contenedor-fotos-icono {
	font-size: 60px;
	margin-top: 100px;
	text-align: center;
}

.avaluos-formulario__contenedor-fotos-texto {
	font-size: 24px;
	margin-bottom: 100px;
	text-align: center;
}

.avaluos-formulario__mapa-barra-herramientas {
	float: left;
}

.avaluos-formulario__botton-editar {
	float: right;
	margin-bottom: 5px;
}

.avaluos-formulario__grupo-checkbox {
	h5 {
		margin-top: 10px;
	}
}

.avaluos-formulario__mapa-botones {
	display: inline-block;
}

.avaluos-formulario__mapa-imagen {
	width: 100%;
}

.avaluos-formulario__mapa-imagen__link {
	width: 100%;
	cursor: zoom-in;
}

.avaluos-formulario__mapa-google__marco {
	border: 3px rgb(200, 195, 195) solid;
	margin: auto;
}

.avaluos-formulario__tabla__sin-detalles {
	text-align: center;
}
