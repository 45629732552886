//****************************************************************************************************
// Imagenes
//****************************************************************************************************
.mc-soft__imagen--bisel {
	-webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
	-moz-clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
	clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}

.mc-soft__imagen--chevron-derecha {
	-webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
	-moz-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
	clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.mc-soft__imagen--chevron-izquierda {
	-webkit-clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
	-moz-clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
	clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}

.mc-soft__imagen--circulo {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.mc-soft__imagen--cuadrado {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.mc-soft__imagen--elipse-horizontal {
	-webkit-clip-path: ellipse(50% 35% at 50% 50%);
	-moz-clip-path: ellipse(50% 35% at 50% 50%);
	clip-path: ellipse(50% 35% at 50% 50%);
}

.mc-soft__imagen--elipse-vertical {
	-webkit-clip-path: ellipse(35% 50% at 50% 50%);
	-moz-clip-path: ellipse(35% 50% at 50% 50%);
	clip-path: ellipse(35% 50% at 50% 50%);
}

.mc-soft__imagen--estrella {
	-webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
	-moz-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
	clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.mc-soft__imagen--heptagono {
	-webkit-clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
	-moz-clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
	clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
}

.mc-soft__imagen--hexagono {
	-webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
	-moz-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
	clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.mc-soft__imagen--nonagono {
	-webkit-clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
	-moz-clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
	clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
}

.mc-soft__imagen--octagono {
	-webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
	-moz-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
	clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}

.mc-soft__imagen--paralelograma {
	-webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
	-moz-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
	clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.mc-soft__imagen--pentagono {
	-webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
	-moz-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
	clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.mc-soft__imagen--punta-derecha {
	-webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
	-moz-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
	clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.mc-soft__imagen--punta-izquerda {
	-webkit-clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
	-moz-clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
	clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
}

.mc-soft__imagen--ranura {
	-webkit-clip-path: polygon(
		0% 15%,
		15% 15%,
		15% 0%,
		85% 0%,
		85% 15%,
		100% 15%,
		100% 85%,
		85% 85%,
		85% 100%,
		15% 100%,
		15% 85%,
		0% 85%
	);
	-moz-clip-path: polygon(
		0% 15%,
		15% 15%,
		15% 0%,
		85% 0%,
		85% 15%,
		100% 15%,
		100% 85%,
		85% 85%,
		85% 100%,
		15% 100%,
		15% 85%,
		0% 85%
	);
	clip-path: polygon(
		0% 15%,
		15% 15%,
		15% 0%,
		85% 0%,
		85% 15%,
		100% 15%,
		100% 85%,
		85% 85%,
		85% 100%,
		15% 100%,
		15% 85%,
		0% 85%
	);
}

.mc-soft__imagen--rombo {
	-webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	-moz-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.mc-soft__imagen--triangulo {
	-webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
	-moz-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.mc-soft__imagen--triangulo-invertido {
	-webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
	-moz-clip-path: polygon(100% 0, 0 0, 50% 100%);
	clip-path: polygon(100% 0, 0 0, 50% 100%);
}
//****************************************************************************************************
// Pagina
//****************************************************************************************************
//----------------------------------------------------------------------------------------------------
// Barra de Herramientas
//----------------------------------------------------------------------------------------------------
.pagina__barra-herramientas {
	text-align: right;
	.btn {
		margin-left: 5px;
	}
}
