.avaluo-detalles__estatus {
	font-size: 30px;
	text-align: right;
}
.avaluo-detalles__etiqueta {
	font-weight: bold;
}
.avaluos-detalles-mapa {
	cursor: zoom-in;
	text-align: center;
}
.avaluo-detalles__tabla-encabezado {
	font-weight: bold;
	text-align: center;
}
.avaluo-detalles__tabla-celda__centrada {
	text-align: center;
}
.avaluo-detalles__tabla-celda__derecha {
	text-align: right;
}
.avaluos-detalles-tabla__sin-detalles {
	text-align: center;
}
.avaluos-detalles-tabla__cantidad-letra {
	text-align: center;
}
.avaluo-detalles__valor {
	margin-left: 10px;
}
p {
	margin-bottom: 0px;
}
.table > :not(caption) > * > * {
	padding: 0px;
}
