.perito-tarjeta {
	color: #74788d;
	h3 {
		margin-bottom: 20px;
	}
	p {
		font-size: 17px;
		margin-bottom: 10px;
	}
}

.perito-tarjeta__licencia-contenedor {
	@extend .perito-tarjeta;
	margin-bottom: 30px;
	:hover {
		cursor: pointer;
	}
}

.perito-tarjeta__licencia {
	width: 100%;
}

.perito-tarjeta__informacion {
	@extend .perito-tarjeta;
	.bg-danger {
		cursor: default;
	}
}
