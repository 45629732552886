//****************************************************************************************************
// Menu
//****************************************************************************************************
//----------------------------------------------------------------------------------------------------
// Separador
//----------------------------------------------------------------------------------------------------
body[data-sidebar="colored"] #sidebar-menu ul li.menu-title {
	background-color: #5b73e8;
	color: rgba(255, 255, 255, 0.7);
}
.dropdown-menu {
	.nav-item {
		&.dropdown {
			.nav-link {
				&.dropdown-togglez {
					&.arrow-none {
						color: #7b8190;
						font-family: "IBM Plex Sans", sans-serif;
						font-weight: 400;
						font-size: 0.9rem;
						line-height: 1.5;
						padding: 0.35rem 1.5rem;
						white-space: nowrap;
						&.active {
							color: #5b73e8 !important;
						}
						&:hover {
							color: #5b73e8 !important;
						}
					}
				}
			}
		}
	}
}
