.domicilio-tarjeta {
	color: #74788d;
	text-align: left;
}

.domicilio-tarjeta__informacion {
	@extend .domicilio-tarjeta;
	margin-bottom: 30px;
	p {
		font-size: 17px;
		margin-bottom: 10px;
	}
}
