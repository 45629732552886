.mc-campo-selector-multiple__input--focused {
	border-color: #2684FF !important;
}

.css-1pahdxg-control{
	border-color: #cccccc !important;
	box-shadow: 0 0 0 0px !important;
}
.css-1pahdxg-control:hover{
	border-color: #cccccc !important;
	box-shadow: 0 0 0 0px !important;
}