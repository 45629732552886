.table-hover > tbody > tr:hover {
	--bs-table-accent-bg: #e0e0e0;
}

.mc-tabla-paginada{
	margin-top: 20px;
	th{
		border: 1px solid #e0e0e0;
	}
	td{
		border: 1px solid #e0e0e0;
		vertical-align: middle;
	}
}

.mc-tabla-paginada__barra-herramientas{
	label {
		margin-right: 10px;
	}
}

.mc-tabla-paginada__celda--imagen{
	height: 50px;
	text-align: center;
}

.mc-tabla-paginada__encabezado-ordenable{
	cursor: pointer;
	&:hover{
		background-color: #999999;
		color: white;
	}
}

.mc-tabla-paginada__paginacion{
	text-align: right;
}

.mc-tabla-paginada__pie-pagina{
	float: right;
}

.mc-tabla-paginada__renglon{
	cursor: pointer;
}

.mc-tabla-paginada__sin-resultados{
	margin-top: 20px;
	text-align: center;
}
.mc-tabla-paginada__sin-resultados-descripcion{
	font-size: 20px;
	margin-top: 20px;
	padding: 20px;
}
.mc-tabla-paginada__sin-resultados-titulo{
	font-size: 30px;
	margin-bottom: 20px;
	padding: 20px;
}

